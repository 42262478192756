<template>
  <div class="error-page">
    <div class="error-image">
      <img src="@/assets/img/404.jpg" alt="">
    </div>
    <div class="error-content-wrapper">
      <div class="error-content-logo d-flex justify-content-center align-items-start">
        <span>4</span>
        <span>
          <errorIcon/>
        </span>
        <span>4</span>
      </div>
      <div class="error-content">
        <p class="error-title">Página não encontrada</p>
        <router-link to="/" class="generic-btn red error-btn">Voltar á homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import errorIcon from "@/components/svg/error.vue";

export default {
name: "error",
  components : {
    errorIcon
  },
}
</script>